import { Link, Column } from "@/components";
import { IViewTableColumn } from "@/components/table/ViewTable";
import { routerMap } from "@/router/agent_router";
import WebApi from "@/web/api";
import LibEnum from "lib/enum";
import React from "react";
import CommonReport from "../components/CommonReport";

export default class SaleOrderReportPage extends React.PureComponent {

  private summaryColumns = [
    {
      title: '订货收入总额(元)',
      field: 'totalSaleAmount',
    },
    {
      title: '订货退货支出总额(元)',
      field: 'totalSaleReturnAmount',
    },
    {
      title: '进货支出总额(元)',
      field: 'totalPurchaseAmount',
    },
    {
      title: '进货退货收入总额(元)',
      field: 'totalPurchaseReturnAmount',
    },
    {
      title: '费用支出总额(元)',
      field: 'totalFeeAmount',
    },
    {
      title: '余额(元)',
      field: 'totalBalanceAmount',
      precision: 2
    },
  ];

  private columns: IViewTableColumn[] = [
    {
      title: '日期',
      dataIndex: 'date',
      width: 200,
      align: 'center',
    },
    {
      title: '订货收入(元)',
      dataIndex: 'saleAmount',
      align: 'center',
      sorter: (a: any, b: any) => a.saleAmount - b.saleAmount,
      render: (value, i: any) => {
        if (i.saleAmount > 0) {
          return <Link to={routerMap.reportManageDetail.getPath(LibEnum.BOOK_TYPE.SALE.value, i.date)}>
            <Column.AmountDirection income>{value}</Column.AmountDirection>
          </Link>
        }
        return value;
      }
    },
    {
      title: '订货退货支出(元)',
      dataIndex: 'saleReturnAmount',
      align: 'center',
      sorter: (a: any, b: any) => a.saleReturnAmount - b.saleReturnAmount,
      render: (value, i: any) => {
        if (i.saleReturnAmount > 0) {
          return <Link to={routerMap.reportManageDetail.getPath(LibEnum.BOOK_TYPE.SALE_RETURN.value, i.date)}>
            <Column.AmountDirection income={false}>{value}</Column.AmountDirection>
          </Link>
        }
        return value;
      }
    },
    {
      title: '进货支出(元)',
      dataIndex: 'purchaseAmount',
      align: 'center',
      sorter: (a: any, b: any) => a.purchaseAmount - b.purchaseAmount,
      render: (value, i: any) => {
        if (i.purchaseAmount > 0) {
          return <Link to={routerMap.reportManageDetail.getPath(LibEnum.BOOK_TYPE.PURCHASE.value, i.date)}>
            <Column.AmountDirection income={false}>{value}</Column.AmountDirection>
          </Link>
        }
        return value;
      }
    },
    {
      title: '进货退货收入(元)',
      dataIndex: 'purchaseReturnAmount',
      align: 'center',
      sorter: (a: any, b: any) => a.purchaseReturnAmount - b.purchaseReturnAmount,
      render: (value, i: any) => {
        if (i.purchaseReturnAmount > 0) {
          return <Link to={routerMap.reportManageDetail.getPath(LibEnum.BOOK_TYPE.PURCHASE_RETURN.value, i.date)}>
            <Column.AmountDirection income>{value}</Column.AmountDirection>
          </Link>
        }
        return value;
      }
    },
    {
      title: '费用支出(元)',
      dataIndex: 'feeAmount',
      align: 'center',
      sorter: (a: any, b: any) => a.feeAmount - b.feeAmount,
      render: (value, i: any) => {
        if (i.feeAmount > 0) {
          return <Link to={routerMap.reportManageDetail.getPath('fee', i.date)}>
            <Column.AmountDirection income={false}>{value}</Column.AmountDirection>
          </Link>
        }
        return value;
      }
    },
    {
      title: '余额(元)',
      dataIndex: 'balanceAmount',
      align: 'center',
      sorter: (a: any, b: any) => a.balanceAmount - b.balanceAmount,
    },
  ];
  render() {
    return <CommonReport
      showEmptyLabelText="仅显示有发生额的日期"
      filter={data => {
        return data.filter(i => (i.saleAmount > 0 || i.saleReturnAmount > 0 || i.purchaseAmount > 0 || i.purchaseReturnAmount > 0 || i.feeAmount > 0));
      }}
      tableColumns={this.columns}
      pagination={false}
      summaryColumns={this.summaryColumns}
      fetch={data => WebApi.report_manage(data)}
    />
  }
}